import { ComponentProps, useState } from "react";
import React from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import rightArrow from "../../assets/rightArrow.svg";
import { verticalMargin } from "../../margins";
import { Heading3, Heading5 } from "../Atoms/Text";
import { colorGrayStroke } from "../../stylingConstants";
import { CrossmarkIcon } from "../Atoms/Icons";
import styled from "@emotion/styled";
import { Button } from "../Atoms/Button";
import Modal from "./Modal";

const HorizontalSpaceFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const catHeadingCss = css`
  margin-top: 18px;
  margin-bottom: 12px;
  color: ${colorGrayStroke};
`;

const listCss = css`
  list-style: none;
  list-style-image: url(${rightArrow});
  padding-left: 22px;
  margin: 0;
`;
const itemCss = css`
  font-weight: 700;
  ${verticalMargin(8)}
`;

export const CategoryOrRolePickerWithButton = ({
  categories,
  buttonText = "Filtrera",
  buttonProps,
  ...props
}: Omit<Parameters<typeof CategoryOrRolePicker>[0], "show" | "onClose"> & {
  buttonText?: string;
  buttonProps?: ComponentProps<typeof Button>;
}) => {
  const [showCatPicker, setShowCatPicker] = useState(false);
  return categories ? (
    <>
      <CategoryOrRolePicker categories={categories} {...props} onClose={() => setShowCatPicker(false)} show={showCatPicker} />
      <Button {...buttonProps} onClick={() => setShowCatPicker(true)}>
        {buttonText}
      </Button>
    </>
  ) : null;
};

export default function CategoryOrRolePicker({
  categories,
  ...props
}: {
  categories: [string, string][];
} & Omit<ComponentProps<typeof Modal>, "children">) {
  return (
    <Modal {...props}>
      <HorizontalSpaceFlex>
        <Heading3>Filtrera</Heading3>
        <CrossmarkIcon
          css={css`
            height: 16px;
            width: 16px;
            cursor: pointer;
          `}
          onClick={props.onClose}
        />
      </HorizontalSpaceFlex>
      <HorizontalSpaceFlex>
        <div
          css={css`
            min-width: 330px;
          `}
        >
          <Heading5 css={catHeadingCss}>Kategorier</Heading5>
          <ul css={listCss}>
            {categories.map(([name, url]) => (
              <li key={name} css={itemCss}>
                <Link to={`/arkiv/kategori/${url}`}>{name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div
          css={css`
            width: 330px;
          `}
        >
          <Heading5 css={catHeadingCss}>Uppdrag</Heading5>
          <ul css={listCss}>
            <li css={itemCss}>
              <Link to={"/arkiv/jury"}>Jury</Link>
            </li>
            <li css={itemCss}>
              <Link to={"/arkiv/talare"}>Talare</Link>
            </li>
            <li css={itemCss}>
              <Link to={"/arkiv/vard"}>Värd</Link>
            </li>
          </ul>
        </div>
      </HorizontalSpaceFlex>
    </Modal>
  );
}
